@media screen and (max-width: 667px) {
    body {
        overflow-x: hidden !important;
    }
    .container {
        max-width: 100% !important;
        overflow-x: hidden !important;
    }
}

.home4 {
    background: #121212;
    overflow: hidden;
    .container {
        padding: 0px 30px;
        @media (max-width: 991px) {
            padding: 30px 0px;
        }
    }
    span.logos {
        font-weight: 900;
        span {
            color: #0575E6;
        }
    }
    .text-right {
        text-align: right;
    }
    .masthead {
        background: #2B32B2;
        // background: #223396;
        background-size: cover;
        background-position: center center;
        transform: skew(0deg, -10deg) translateY(-140px);
        min-height: 600px;
        display: flex;
        align-items: center;
        padding: 130px 0px 0px;
        position: relative;

        @media (max-width: 767px) {
            padding: 150px 30px 0px;
        }
        .pattern1 {
            transform: skew(0deg, 10deg);
            position: absolute;
            left: 0px;
            top: 130px;
        }
        .pattern2 {
            position: absolute;
            transform: skew(0deg, 10deg);
            right: 60px;
            top: 130px;
        }
        .container {
            transform: skew(0deg, 10deg);
            padding-top: 200px;
            padding-bottom: 200px;
            @media (max-width: 500px) { 
                padding-top: 120px;
                padding-bottom: 150px;
            }
        }
        h1 {
            font-family: 'Vollkorn';
            font-style: normal;
            font-weight: 800;
            font-size: 50px;
            line-height: 120%;
            text-transform: capitalize;
            color: #fff;
            margin-bottom: 18px;
            @media (max-width: 500px) { 
                font-size: 35px;
            }
            span {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    left: 0px;
                    bottom: 16px;
                    width: 100%;
                    height: 14px;
                    background: #69b4ffb6;
                    z-index: -1;
                    @media (max-width: 500px) { 
                        bottom: 10px;
                    }
                }
            }
        }
        p {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: #fff;
            margin-bottom: 24px;
            span {
                font-weight: 600;
                // text-transform: capitalize;
            }
            @media (max-width: 500px) { 
                font-size: 16px;
            }
        }
        p.sub-text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: #d1d7ff;
            opacity: .8;
            margin-bottom: 0px;
        }
        .button-group {
            display: flex;
            gap: 20px;
            margin-bottom: 8px;
            flex-wrap: wrap;
        }
        .btn {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.3px;
            padding: 16px 32px;
            @media (max-width: 500px) {
                padding: 16px;
            }
        }
        .btn-primary {
            border: 2px solid #FFCD05;
            background: #FFCD05;
            color: #121212;
            border-radius: 8px;
            transition: all 0.25s ease-in-out;
            box-shadow: 0px 2px 8px rgba(97, 91, 7, 0.15);
            &:hover {
                border: 2px solid #ffd428;
                background: #ffd428;
                color: #000;
            }
        }
        .btn-secondary {
            border: none;
            color: #ffffff;
            padding: 16px 0px;
            display: flex;
            align-items: center;
            background: transparent;
            opacity: .8;
            border-radius: 8px;
            i {
                margin-left: 10px;
                font-weight: 900;
                transition: all 0.25s ease-in-out;
            }
            &:hover {
                background: transparent;
                color: #fff;
                opacity: 1;
                i {
                    margin-left: 15px;
                }
            }
            @media (max-width: 423px) {
                font-size: 14px;
            }
        }
        a {
            text-decoration: none;
        }
        img.img-main {
            border-radius: 8px;
            // background: #fff;
            box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.44);
            @media (max-width: 991px) { 
                margin-top: 50px;
            }
        }
    }
    .services {
        background: #FDF8F2;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0px 0px 0px;
        transform: skew(0deg, -10deg) translateY(-140px);
        @media (max-width: 767px) {
            padding: 0px 30px;
        }
        .container {
            position: relative;
            transform: skew(0deg, 10deg);
            padding-top: 200px;
            padding-bottom: 200px;
            @media (max-width: 500px) {
                padding-top: 100px;
                padding-bottom: 100px;
            }
        }
        .pattern1 {
            position: absolute;
            top: 25px;
            left: 0px;
            transform: skew(0deg, 10deg);
        }
        .memojis {
            position: absolute;
            top: 0px;
            right: 50px;
        }
        .arrows-img {
            position: absolute;
            top: 250px;
            right: 28px;
            width: 50px;
        }
        h1 {
            font-family: 'Vollkorn';
            font-style: normal;
            font-weight: 800;
            text-transform: capitalize;
            font-size: 50px;
            line-height: 120%;
            color: #2B32B2;
            margin-bottom: 8px;
            @media (max-width: 500px) { 
                font-size: 35px;
            }
            span {
                position: relative;
                font-weight: 800;
                &::after {
                    content: "";
                    position: absolute;
                    left: 0px;
                    bottom: 16px;
                    width: 100%;
                    height: 14px;
                    background: #ffe57b;
                    z-index: -1;
                    @media (max-width: 500px) { 
                        bottom: 10px;
                    }
                }
            }
        }
        p.sub-text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #121212;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            span {
                font-weight: 400;
            }
            i {
                font-size: 28px;
                margin-right: 15px;
                color: #292524;
                font-weight: 900;
            }
            img {
                margin-right: 15px;
            }
            @media (max-width: 500px) {
                font-size: 16px;
            }
        }
        p.sub-texts {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #121212;
            margin-bottom: 16px;
            // display: flex;
            // align-items: center;
            span {
                font-weight: 400;
            }
            i {
                font-size: 28px;
                margin-right: 15px;
                color: #292524;
                font-weight: 900;
            }
            @media (max-width: 500px) {
                font-size: 16px;
            }
        }
        sub.green-tag {
            color: #089d55;
            font-weight: 600;
            text-transform: uppercase;
        }
        .list {
            p {
                line-height: 180%;
            }
        }
        span {
            font-weight: 700;
        }
        .service-first {
            padding-top: 30px;
            padding-bottom: 100px;
            @media (max-width: 767px) {
                padding-top: 0px;
            }
        }
        .service-item {
            .badge {
                background: #121212;
                width: 50px;
                height: 50px;
                margin: 0px auto;
                display: flex;
                justify-content: center;
                align-items: center;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 200%;
                letter-spacing: -0.3px;
                color: #FFFFFF;
            }
            .border-end {
                border-right: 1px solid #021c7980!important;
            }
            .content {
                padding: 50px 0px 100px;
                @media (max-width: 500px) { 
                    padding: 50px 0px 50px;
                } 
            }
            .content2 {
                padding: 9px 20px 0px 0px;
                @media (max-width: 991px) { 
                    padding: 50px 0px 0px;
                } 
                @media (max-width: 767px) { 
                    padding: 50px 0px 0px;
                } 
            }
            .tag {
                padding: 10px;
                font-size: 13px;
                border-radius: 8px;
                background: #FFCD05;
                color: #121212;
                @media (max-width: 500px) { 
                    font-size: 12px;
                } 
            }
            h3 {
                font-family: 'Vollkorn';
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 120%;
                margin-bottom: 8px;
                margin-top: 16px;
                text-transform: capitalize;
                color: #121212;   
                @media (max-width: 500px) { 
                    margin-top: 30px;
                    font-size: 30px;
                }         
            }
            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
                color: #121212;
                margin-bottom: 14px;
                span {
                    text-decoration: none;
                    color: #121212;
                    cursor: text;
                    font-weight: 500;
                }
                @media (max-width: 500px) { 
                    font-size: 16px;
                }   
            }
            p.love-it {
                font-weight: 600;
                font-size: 16px;
                text-transform: uppercase;
                margin-bottom: 10px;
            }
        }
        .btn {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.3px;
            &:focus {
                box-shadow: none;
            }
        }
        .btn-primary {
            padding: 16px 32px;
            background: transparent;
            margin-top: 40px;
            border: none;
            padding: 10px 0px;
            border-bottom: 3px solid #2B32B2;
            color: #2B32B2;
            border-radius: 0px;
            img {
                margin-left: 10px;
            }
            &:hover {
                border-bottom: 3px solid #2B32B2;
            }
        }
        .benefit-list {
            .benefit-item {
                border: 2px solid transparent;
                // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                border-radius: 8px;
                padding: 20px 30px 26px;
                background: #2B32B2;
                background: transparent;
                box-shadow: none;
                padding: 0px;
                letter-spacing: -0.3px;
                margin-top: 29px;
                cursor: pointer;
                position: relative;
                &:first-child {
                    margin-top: 20px;
                }
                transition: all 0.15s ease-in-out;
                &.active, &:hover {
                    // box-shadow: rgba(50, 50, 93, 0.438) 0px 6px 12px -2px, rgba(11, 56, 114, 0.3) 0px 3px 7px -3px;
                    p {
                        opacity: 1 !important;
                    }
                }
                .header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                }
                p {
                    margin-bottom: 0px;
                    font-weight: 400;
                    line-height: 180%;
                    font-size: 18px;
                    // color: #fff;
                    transition: all 0.15s ease-in-out;
                    @media (max-width: 767px) { 
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
                p.title {
                    font-weight: 600;
                    opacity: 1;
                    font-size: 20px;
                    line-height: 150%;
                    display: flex;
                    align-items: center;
                    // color: #fff;
                    margin-bottom: 8px;
                }
                img {
                    margin-right: 10px;
                }
                i {
                    font-size: 30px;
                    background: transparent;
                    padding: 4px;
                    margin-right: 15px;
                    // color: #fff;
                }
            }
        }
        .many-others {
            margin-top: 50px;
            p {
                font-style: italic;
            }
        }
    }
    .faq {
        background: #121212;
        padding: 50px 0px 50px;

        @media (max-width: 767px) { 
            padding: 0px 30px 30px;
            margin-top: -50px;
        }
        
        h1 {
            font-family: 'Vollkorn';
            font-style: normal;
            font-weight: 800;
            text-align: center;
            text-transform: capitalize;
            font-size: 50px;
            line-height: 120%;
            color: #fff;
            margin-bottom: 8px;
            @media (max-width: 500px) { 
                font-size: 35px;
            }
            span {
                color: #FFCD05;
            }
        }
        p {
            font-family: 'Inter';
            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 125%;
            color: #FFFFFF;
            margin-bottom: 120px;
            @media (max-width: 500px) { 
                margin-bottom: 60px;
                font-size: 16px;
            }
        }
        .border-gradient {
            background: linear-gradient(to right, #FADE2A, #FA2A2A, #2AFA96, #2A32FA) border-box;
            border: 4px solid transparent;
            // transition: all 0.4s ease-in-out;
            img {
                transform: rotate(180deg);
            }
        }
        .faq-item {
            margin-bottom: 34px;
            border-radius: 8px;
            border: 2px solid transparent;
            border: 4px solid rgba(28, 28, 28, 0.08);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            // transition: all 0.4s ease-in-out;
            cursor: pointer;
            .faq-content {
                padding: 40px;
                background: #1C1C1C;
                border-radius: 8px;
                text-align: left;
                @media (max-width: 500px) { 
                    padding: 20px;
                }
                p.question {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 150%;
                    color: #FFFFFF;
                    text-align: left;
                    display: flex;
                    margin-bottom: 0px;
                    justify-content: space-between;
                    img {
                        margin-left: 10px;
                    }
                    @media (max-width: 500px) { 
                        font-size: 18px;
                    }
                }
                p.answer {
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 180%;
                    color: #FFFFFF;
                    text-align: left;
                    margin-bottom: 0px;
                    margin-top: 25px;
                }
            }
        }
    }
    .cta {
        background: #2B32B2;
        padding: 30px 0px 50px;
        @media (max-width: 767px) {
            padding: 0px 30px;
        }
        .content {
            background: #121212;            
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            margin-top: 70px;
            margin-bottom: 50px;
            border-radius: 8px;
            padding: 60px 60px;
            h1 {
                color: #fff;
                font-size: 50px;
                margin-bottom: 16px;
                font-family: 'Vollkorn';
                font-style: normal;
                font-weight: 800;
                line-height: 120%;
                span {
                    position: relative;
                    color: #FFCD05;
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0px;
                        bottom: 4px;
                        width: 100%;
                        height: 14px;
                        background: #4698eb;
                        z-index: -1;
                    }
                }
                @media (max-width: 500px) { 
                    font-size: 35px;
                    margin-bottom: 8px;
                }
            }
            p {
                color: #fff;
                font-weight: 600;
                margin-bottom: 24px;
                line-height: 150%;
                opacity: .9 !important;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                @media (max-width: 500px) { 
                    margin-bottom: 60px;
                    font-size: 16px;
                }
            }
            .btn-primary {
                background: #FFCD05;
                border: 2px solid #FFCD05;
                font-family: 'Inter';
                color: #121212;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
                letter-spacing: -0.3px;
                padding: 16px 32px;
                &:hover {
                    background: #fff;
                    border: 2px solid #fff;
                    color: #121212;
                }
                @media (max-width: 500px) {
                    padding: 16px;
                }
            }
            @media (max-width: 991px) {
                .text-right {
                    text-align: left;
                }
            }
            @media (max-width: 767px) {
                padding: 60px 60px;
            }
            @media (max-width: 500px) {
                padding: 60px 30px;
            }
        }
    }
}