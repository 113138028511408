.modal {
    .modal-dialog {
      max-width: 600px;
    }
    
    .modal-body {
      padding: 40px 40px 30px;
      background: #fff;
      border-radius: 8px;
      h3 {
        font-family: 'Vollkorn';
        font-style: normal;
        font-weight: 800;
        font-size: 34px;
        text-align: left;
        line-height: 120%;
        color: #2B32B2;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        img {
          margin-bottom: 0px;
        }
        @media (max-width: 500px) {
          font-size: 28px;
          text-align: left;
        }
      }
    
      p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        text-align: left;
        font-size: 16px;
        line-height: 150%;
        color: #121212;
        margin-bottom: 40px;

        span {
          color: #121212;
          font-weight: 600;
          padding: 5px;
          border-radius: 8px;
          background: #FFCD05;
        }
        @media (max-width: 500px) {
          font-size: 16px;
          text-align: left;
        }
      }

      p.info-text {
        font-size: 14px;
        text-align: center;
        margin: 10px 0px 0px;
      }
    
      .form-group {
        margin-bottom: 20px;
      }
    
      .form-check {
        margin: 8px 0px 16px;
        .form-check-label {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          color: #1C1C1C;
        }
      }
    
      .form-check-input:checked {
        background-color: #0458C2;
        border-color: #0458C2;
      }
    
      label.features-label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #6b6b6b;
        margin-bottom: 4px;
      }
    
      input.form-control {
        background: #fff;
        border-radius: 8px;
        height: 50px;
        // border: none;
    
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.3px;
        color: #1C1C1C;
    
        &::placeholder {
          opacity: 0.3;
        }
      }

      input.form-error {
        border-color: crimson;
      }
    
      .btn-block {
        width: 100%;
        padding: 16px 32px;
        background: #2B32B2;
        border-radius: 8px;
        border: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.3px;
        color: #fff;
        margin-top: 16px;
      }

      .business-count {
        margin-top: 16px;
        font-weight: 800;
        span {
          font-size: 12px;
          background: #3bf85b62;
          word-wrap: break-word;
          display: block;
          border-radius: 8px;
          padding: 10px;
          margin-bottom: 0px;
          text-align: center;
          font-weight: 600;
        }
      }
    }

    .success-pane {
      padding-bottom: 20px;
      h3, p {
        text-align: center;
      }
      p.share {
        margin-top: 26px;
        margin-bottom: 16px;
      }
      .success-img {
        width: 200px;
        min-height: 200px;
      }
      .socials {
        display: flex;
        justify-content: center;
        gap: 20px;
        a {
          font-size: 25px;
          padding: 15px;
          border-radius: 50%;
          height: 60px;
          width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          color: #2B32B2;
          background: #f2f2f2;
        }
      }
      .copy-link {
        display: flex;
        margin-top: 20px;
        gap: 20;
        .link-text {
          flex: 1;
          border: 1px solid #121212;
          background: #fff;
          border-radius: 8px;
          margin-right: 5px;
          padding: 15px 15px;
        }
        button {

        }
      }
    }

    .btn-close {
      position: absolute;
      right: 20px;
      &:focus {
        box-shadow: none;
      }
    }

    @media (max-width: 500px) {
      .modal-body {
        padding: 30px 30px 20px;
      }
    }
}

.toast-pane {
  color: #fff;
}