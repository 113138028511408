.navbar {
    background: #fff;
    padding: 5px 40px;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    // box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 18px 50px -10px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    .navbar-brand {
        padding: 16px 0px;
        i {
            margin-right: 10px;
            color: #2B32B2;
        }
        img {
            margin-right: 10px;
            width: 170px;
            @media (max-width: 500px) {
                width: 130px;
            }
        }
        color: #2B32B2;
        font-size: 28px;
        font-weight: 900;
        font-family: 'arial';
        span {
            color: #2B32B2;
        }
        @media (max-width: 767px) {
            font-size: 20px;
        }
    }
    .navbar-nav {
        display: flex;
        align-items: center;
        gap: 30px;
    }
    .nav-link {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.3px;
        color: #121212;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            margin-left: 10px;
        }
        &:hover {
            color: #2B32B2;
        }
        &.show {
            color: #0575E6;
            &::after {
                content: "";
                bottom: 0px;
                display: block;
                text-align: center;
                position: absolute!important;
                background: #0575E6;
                width: 65px;
                height: 2px;
                // border-radius: 12px 12px;
                opacity: 1;
                transition: all .3s ease-in-out;
            }
        }
    }
    .dropdown-menu-center {
        right: auto;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }
    .dropdown-menu[data-bs-popper] {
        margin-top: 2rem;
        border-radius: 8px;
        background: #121212;
        min-width: 500px;
        padding: 20px 20px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.8), 0 4px 12px rgba(0, 0, 0, 0.36), inset 0 0 0 0.5px rgba(237, 237, 237, 0.36);
        h5 {
            margin-bottom: 0px;
            font-size: 16px;
            font-weight: 600;
        }
        p {
            opacity: 0.7;
            font-size: 14px;
            margin-bottom: 0px;
        }
        a {
            text-decoration: none;
            color: inherit;
        }
        .why-item {
            margin-bottom: 20px;
            padding: 15px;
            border-radius: 8px;
            &:hover {
                background: #333;
                h5 {
                    color: #F8BC3B;
                }
            }
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
    .ml-auto {
        margin-left: auto;
    }
    .navbar-nav.navbar-center {
        position: absolute;
        left: 50%;
        transform: translatex(-50%);
    }
    .navbar-toggler, button.btn-main {
        padding: 10px 32px;
        font-size: 16px !important;
        font-weight: 500;
        font-family: Inter;
        color: #fff;
        background: #2B32B2;
        border: 2px solid #2B32B2;
        border-radius: 8px;
        // box-shadow: 0px 2px 8px rgba(97, 91, 7, 0.15);
        &:hover {
            background: #334294;
            border: 2px solid #334294;
            color: #fff;
        }
        &:focus, &:hover {
            box-shadow: none !important;
        }
        @media (max-width: 767px) {
            padding: 10px;
            font-size: 14px !important;
        }
    }
    @media (max-width: 767px) {
        padding: 5px 10px;
    }
}
.btn {
    &:focus, &:hover {
        box-shadow: none !important;
    }
}